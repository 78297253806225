/* .inter {
  font-family: Inter;
}
.satoshi {
  font-family: Satoshi;
}
.hero {
  background: rgb(16, 15, 87);
  background: linear-gradient(
      11deg,
      rgba(16, 15, 87, 0.8250875350140056) 71%,
      rgba(72, 18, 122, 0.873546918767507) 100%
    ),
    url("./assets/herobg.png");
}
.about {
  background: rgba(34, 66, 180, 1);
  background: linear-gradient(
      102deg,
      rgba(34, 66, 180, 0.9) 0%,
      rgba(34, 66, 180, 0.7) 100%
    ),
    url("./assets/aboutbg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.contact {
  background: linear-gradient(
      102deg,
      rgba(4, 3, 46, 0.9) 800%,
      rgba(34, 66, 180, 0.9) 20%
    ),
    url("./assets/contactbg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.event {
  background: linear-gradient(
      102deg,
      rgba(16, 15, 87, 0.8920343137254902) 100%,
      rgba(34, 66, 180, 0.9) 0%
    ),
    url("./assets/evemtsbg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.faq {
  background: linear-gradient(
      102deg,
      rgba(17, 17, 56, 0.5) 0%,
      rgba(26, 51, 138, 0.1) 100%
    ),
    url("./assets/faqbg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.karla {
  font-family: Karla;
}
.kum {
  font-family: Kum;
}
.testimonial {
  background: linear-gradient(
      11deg,
      rgba(28, 28, 30, 0.8166841736694677) 84%,
      rgba(255, 255, 255, 1) 100%
    ),
    url("./assets/testimonial.png");
  background-position: center;
  background-size: cover;
} */

@font-face {
  font-family: Nunito;
  src: url("https://fonts.googleapis.com/css?family=Nunito:200,300,regular,500,600,700,800,900,200italic,300italic,italic,500italic,600italic,700italic,800italic,900italic");
}
@font-face {
  font-family: Segoe UI;
  src: url("../src/assets/fonts/segoe.ttf");
}

@font-face {
  font-family: Inter;
  src: url("../src/assets/fonts/inter.ttf");
}
@font-face {
  font-family: Manrope;
  src: url("../src/assets/fonts/regular.otf");
}
@font-face {
  font-family: Poppins;
  src: url("../src//assets/fonts/Poppins.otf");
}
@font-face {
  font-family: Sansation;
  src: url("../src/assets/fonts/Sansation_Regular.ttf");
}
@font-face {
  font-family: Calibri;
  src: url("./assets/fonts/calibril.ttf");
}
@font-face {
  font-family: "Microsoft San Serif";
  src: url("./assets/fonts/micross.ttf");
}
@font-face {
  font-family: "Manrope", sans-serif;
  src: url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");
}
@font-face {
  font-family: "Albert Sans", sans-serif;
  src: url("https://fonts.googleapis.com/css2?family=Albert+Sans:ital,wght@0,100..900;1,100..900&display=swap");
}

@font-face {
  font-family: Satoshi;
  src: url("./assets/fonts/Satoshi-Regular.otf");
}

@font-face {
  font-family: "Kumbh Sans", sans-serif;
  src: url("https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@100..900&display=swap");
}

.Kumbh {
  font-family: "Kumbh Sans", sans-serif;
}

.Satoshi {
  font-family: Satoshi !important;
}

.Poppins {
  font-family: Poppins !important;
}

.albertSans {
  font-family: Albert San;
}

.printOnly {
  font-family: Calibri !important;
}
.sansation {
  font-family: Sansation !important;
}
.nunito {
  font-family: Nunito !important;
}
.segoe {
  font-family: Segoe UI;
}
.inter {
  font-family: Inter;
}
.manrope {
  font-family: Manrope;
}
