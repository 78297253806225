.flex-center {
  display: flex;
  align-items: center;
}
@keyframes marquee {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}

.marquee {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  animation: marquee 10s linear infinite;
}
